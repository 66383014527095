<template>
  <div class="flex items-center justify-center w-full" style="height: 100vh">
    <div class="flex items-center flex-col gap-4">
      <i class="text-5xl text-blue-900 el-icon-loading"></i>
      <div class="font-bold text-xl">
        {{ $t("Đang lấy thông tin người dùng") }}
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '@/utils/appUtils'

export default {
  name: 'ReceiptDetail',
  data () {
    return {
      isLoading: false
    }
  },
  created () {
    this.handleAutoLogin()
  },
  methods: {
    async handleAutoLogin () {
      const authToken = this.$route.params.token
      const person_id = Number(this.$route.params.person_id)
      const slip_id = this.$route.params.slip_id

      if (!authToken || !person_id || !slip_id) {
        this.$message({
          type: 'error',
          message: 'Không tin thấy thông tin người dùng'
        })

        this.$router.replace({
          name: 'Home'
        })
        return
      }

      this.isLoading = true
      appUtils.setLocalToken(authToken)

      try {
        const response = await this.$rf.getRequest('AuthRequest').me()
        if (response.code === 200) {
          appUtils.setLocalUser(response.data)

          const params = {
            person_id
          }

          const responseConfirm = await this.$rf
            .getRequest('AuthRequest')
            .confirmValidateUser(params)
          this.isLoading = false

          if (responseConfirm.status === 200) {
            this.$router.replace({
              name: 'ReceiptDetail',
              params: {
                id: slip_id
              }
            })
          }
        }
      } catch (error) {
        this.$message({
          type: 'error',
          message: 'Không tin thấy thông tin người dùng'
        })
        this.$router.replace({
          name: 'Home'
        })
        this.isLoading = false

        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
